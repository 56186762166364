import styles from './AllClientsInstallmentsMothsTable.module.css'
import { Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import './AllClientsInstallmentsMothsTable.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PencilIcon} from 'assets/icons/pencilIcon.svg'
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
function AllClientsInstallmentsMothsTable(props:any) {
    const {t}= useTranslation()
    const { items ,getUserInstallments ,deleteItem,isDeleting,getAllUsers,installment,order ,receiveTestment} = props
    const user =useSelector((state:any)=>state?.GlobalReducer?.user)
    const [payingAmount ,setPayingAmount] = useState<any>([])
    const params = useParams()
    const [isLoading ,setIsLoading] = useState(false)
    
    function payInstallment(installmentMonthId:String,index:any,installmentId:any){
        if(!payingAmount?.[index]){
            toast.error(t('Paying Amount Is Required'))
            return
        }
        setIsLoading(true)
        
        let modifiedPayingAmount = [...payingAmount]
        modifiedPayingAmount[index]=0
        setPayingAmount(modifiedPayingAmount)
        const formData  = new FormData()
        formData.append('paying_amount',payingAmount?.[index])
        formData.append('installment_id',String(installmentId))
        formData.append('installment_month_id',String(installmentMonthId))
        axiosConfig.post(`/user/pay-installment-month-for-user/${params?.id}`,formData,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
        }).then(res=>{
            setIsLoading(false)
            toast.success(t(`User Installment Month Paid Successfully Successfully`))
            getUserInstallments()
            setPayingAmount([])
            let payingAmountItems=items?.length ||0
            setPayingAmount([...Array(payingAmountItems)])
        }).catch(err=>{
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        })
    }
    function handleChangePayingAmount(value:any,index:any,paidAmount:any,price:any){
        if(Number(value)+Number(paidAmount)>Number(price)){ 
            value=price-paidAmount
        }
        let modifiedPayingAmount = [...payingAmount]
        modifiedPayingAmount[index]=value
        setPayingAmount(modifiedPayingAmount)
    }
    // useEffect(()=>{
    //     setPayingAmount([])
    //     let payingAmountItems=items?.length ||0
    //     setPayingAmount([...Array(payingAmountItems)])
    // },[items])
    return (
        <>
            <Table responsive striped className={`${styles['appointments__table']} table table-responsive`}>
                <thead>
                    <th>{t('Product Name')}</th>
                    <th>{t('Price')}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Paid Status")}</th>
                    {/* <th>{t("Transaction Date")}</th> */}
                    <th>{t("Paid Amount")}</th>
                    <th>{t("Paying")}</th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        items &&items?.map((item:any,index:number)=>(
                            <tr key={item?.id}>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {installment?.product_name} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.price} {t('AED')}</p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.date} </p>
                                </td>
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}>
                                        {item?.paid_status=='not_paid'?t('Not Paid'):t('Paid')}
                                    </p>
                                </td>
                                {/* <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.created_at} </p>
                                </td> */}
                                <td>
                                    <p className={`${styles['appointments__table-name']} text-capitalize`}> {item?.paid_amount} {t('AED')}</p>
                                </td>
                                <td>
                                    {
                                        item?.paid_status=='not_paid' &&
                                            <div className={styles['form__content-form-input-wrapper']}>
                                                <input  
                                                    type='number'
                                                    className={styles['form__content-form-input']}
                                                    placeholder={`${t('Paying Amount')}`}
                                                    value={payingAmount?.[index]}
                                                    onChange={(e)=>handleChangePayingAmount(e.target.value,index,item?.paid_amount,item?.price)}
                                                />
                                                <button onClick={()=>{payInstallment(item?.id,index,installment?.id)}}
                                                    className={styles['appointments__table-received']}>
                                                    {t('Paying')}
                                                </button>
                                            </div>
                                    }
                                </td>
                                <td>
                                    {
                                        user?.permission_type=='admin' &&
                                        <>
                                        
                                            {/* <button onClick={()=>{deleteItem(params?.id,index,item?.id)}} disabled={isDeleting[index]}
                                                className={styles['appointments__table-delete']}>
                                                {
                                                    !isDeleting[index]?t('Delete')
                                                    :<ReactLoading type={"spin"} color={'red'} height={20} width={20} />
                                                }
                                            </button> */}
                                            
                                            {
                                            item?.paid_status=='paid'?
                                            <button onClick={()=>{receiveTestment(order?.id,index,item?.id,'not_paid')}}
                                                className={styles['appointments__table-received']}>
                                                {t('Paid')}
                                            </button>
                                            :
                                            <button onClick={()=>{receiveTestment(order?.id,index,item?.id,'paid')}}
                                                className={styles['appointments__table-not-received']}>
                                                {t('Not Paid')}
                                            </button>
                                            }
                                        </>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default AllClientsInstallmentsMothsTable